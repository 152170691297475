@font-face {
 /* inter black */
 font-family: "Inter-Black";
 src: local("Inter-Light") url(./fonts/static/Inter-Black.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: 'Inter-Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 480px) {
  body {
      overflow-x: hidden !important;
  }
  .App {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
  .MuiPopover-paper.MuiMenu-paper {
    left: 8px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  body {
      overflow-x: hidden !important;
  }
  .App {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}

@media screen and (min-width: 769px) {
  body {
      overflow-x: hidden !important;
  }
  .App {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}

@media screen and (max-width: 1024px) {
  body {
      overflow-x: hidden !important;
  }
  .App {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}

