@font-face {
  /* inter black */
  font-family: "Inter-Black";
  src: local("Inter-Light") url(./fonts/static/Inter-Black.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: 'Inter-Black', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
